import { Global } from "@emotion/react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { React } from "react"
import Breadcrumbs from "../components/breadcrumbs"
import Container from "../components/container"
import ContainerPost from "../components/container-post"
import Header from "../components/navigation"
import Icon from "../components/icon"
import IconLinkedin from "../images/svgs/linkedin.svg"
import IconInstagram from "../images/svgs/instagram.svg"
import LogoBSG from "../../content/assets/logo.svg"
import LogoNav from "../../content/assets/gleichstellungsberichte-logo.svg"
import ScrollTop from "../components/scroll-top-button"
import tw, { css, GlobalStyles } from "twin.macro"

const Layout = props => {
  const { isHomePage, children } = props
  const breadcrumbs = props.data?.breadcrumbs?.seo?.breadcrumbs || [];
  const nodeType = props.data?.breadcrumbs?.nodeType || "";
  let contentCategories = []

  if (nodeType == "Post") {
    contentCategories = props.data?.page.categories || [];
  }

  if (nodeType == "Blog") {
    contentCategories = props.data?.blog.categories || [];
  }

  let existCategory = contentCategories.length == 0 ? false : contentCategories.nodes.some((category) => {
    return category.id == "dGVybToxMjEy"
  })

  if (nodeType == "Page") {
    const pagesBlue = ['cG9zdDo3MTk1', 'cG9zdDo3MDMz', 'cG9zdDo3MDI5', 'cG9zdDo3MDI1', 'cG9zdDo1NTY=', 'cG9zdDoxMDUx']
    existCategory = props.data?.page.id == "cG9zdDoyNjA2" ? true : ((pagesBlue.includes(props.data?.page.id) ? false : null));
  }

  const {
    mainMenu: { nodes: mainMenuItems },
    footerMenu: { nodes: footernMenuItems },
    metaMenu: { nodes: metaMenuItems },
    legalMenu: { nodes: legalMenuItems },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      mainMenu: allWpMenuItem(
        filter: { locations: { eq: PRIMARY }, parentId: { eq: null } },
        sort: {fields: order, order: ASC}
      ) {
        nodes {
          id
          label
          uri
          childItems {
            nodes {
              id
              label
              uri
            }
          }
        }
      }
      metaMenu: allWpMenuItem(
        filter: { locations: { eq: SECONDARY }, parentId: { eq: null } },
      ) {
        nodes {
          id
          label
          uri
          url
        }
      }
      footerMenu: allWpMenuItem(filter: { locations: { eq: FOOTER } }) {
        nodes {
          id
          path
          label
          url
        }
      }
      legalMenu: allWpMenuItem(filter: { locations: { eq: LEGAL } }) {
        nodes {
          id
          path
          label
          url
        }
      }
    }
  `)

  return (
    <div>
      <GlobalStyles />
      <Global
        styles={css`
          body {
            ${tw`antialiased font-regular`}
            color: #25303B;
          }
          html {
            scroll-padding-top: 96px;
            @media (prefers-reduced-motion: no-preference) {
              scroll-behavior: smooth;
            }
            @media (min-width: 1024px) {
              scroll-padding-top: 172px;
            }
            @media (min-width: 1240px) {
              scroll-padding-top: 124px;
            }
            /*
            @media (min-width: 1240px) {
              font-size: calc(12.25px + 0.3vw);
              -webkit-marquee-increment: 0vw;
            }
            @media (min-width: 1920px) {
              font-size: 18.007px;
            }
            */
          }
          p.small {
            font-size: 80%;
          }
        `}
      />
      <div
        tw="relative flex min-h-screen flex-col items-stretch"
        className="global-wrapper"
        data-is-root-path={isHomePage}
      >
        <Header
          mainMenuItems={mainMenuItems}
          footernMenuItems={footernMenuItems}
          metaMenuItems={metaMenuItems}
        />

        <main tw="mt-32 grow lg:mt-72 xl:mt-[13.2rem]" role="main">
          {breadcrumbs && breadcrumbs.length > 1 && (
            <div>
            {(nodeType == 'Blog' || nodeType == 'Post' || nodeType == 'Page') && (
              <ContainerPost existCategory={existCategory}>
                <div tw="grid grid-cols-12 lg:gap-5">
                  <Breadcrumbs items={breadcrumbs} type={nodeType} existCategory={existCategory} />
                </div>
              </ContainerPost>
            )}
            </div>
          )}
          {children}
          <ScrollTop />
        </main>

        <footer tw="flex-shrink-0" role="contentinfo">
          <div tw="bg-[#403882] py-10 md:py-16">
            <Container>
              <div tw="flex flex-wrap items-start md:gap-5 md:text-lg lg:flex-nowrap">
                <div tw="w-full pb-4 md:pb-0 lg:w-1/5">
                  <a href="https://www.gleichstellungsbericht.de/">
                    <Icon
                      svg={LogoNav}
                      title="Bundesstiftung Gleichstellung"
                      tw="h-auto w-[150px]"
                      asImage
                    />
                  </a>
                </div>
                <div tw="w-full pb-4 md:pb-0 lg:w-1/5 h-auto [vertical-align: top]">
                  {footernMenuItems.map(({ label, url, id }) => (
                    <Link
                      to={url || `/`}
                      tw="flex w-32 flex-wrap items-center duration-300 ease-in-out lg:mb-2 lg:w-40 lg:transition-all xl:mb-0 xl:w-48 text-white [font-size: 16px] [line-height: 130%] [padding: 0 0 10px 0]"
                      aria-label="Footer"
                      key={id}
                    >{label}
                    </Link>
                  ))}
                </div>
                <div tw="w-full pb-4 md:pb-0 lg:w-1/5 h-auto [vertical-align: top]">
                  {metaMenuItems.map(({ label, url, id }) => (
                    <Link
                      to={url || `/`}
                      tw="flex w-32 flex-wrap items-center duration-300 ease-in-out lg:mb-2 lg:w-40 lg:transition-all xl:mb-0 xl:w-48 text-white [font-size: 16px] [line-height: 130%] [padding: 0 0 10px 0]"
                      aria-label="Footer"
                      key={id}
                    >{label}</Link>
                  ))}

                </div>
                <div tw="w-full pb-4 md:pb-0 lg:w-1/5">
                  {legalMenuItems.map(({ label, url, id }) => (
                    <Link
                      to={url || `/`}
                      tw="flex w-32 flex-wrap items-center duration-300 ease-in-out lg:mb-2 lg:w-40 lg:transition-all xl:mb-0 xl:w-48 text-white [font-size: 16px] [line-height: 130%] [padding: 0 0 10px 0]"
                      aria-label="Footer"
                      key={id}
                    >{label}</Link>
                  ))}
                </div>
                <div tw="w-full pb-4 md:pb-0 lg:w-1/5">
                  <div tw="flex">
                    <a
                      href="https://www.instagram.com/bs.gleichstellung/"
                      target="_blank"
                      rel="noreferrer noopener"
                      tw="h-11 w-11 items-center rounded-full p-2 text-white motion-safe:transition-colors motion-safe:duration-300"
                      aria-label="Instagram"
                    >
                      <Icon svg={IconInstagram} tw="h-7 w-7 fill-current" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/bundesstiftung-gleichstellung"
                      target="_blank"
                      rel="noreferrer noopener"
                      tw="h-11 w-11 items-center rounded-full p-2 text-anthracite-500 hover:text-lilac-500 focus:text-lilac-500 motion-safe:transition-colors motion-safe:duration-300"
                      aria-label="LinkedIn"
                    >
                      <Icon svg={IconLinkedin} tw="h-7 w-7 fill-current" />
                    </a>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          <div tw="bg-[#F2EBE8] md:py-6">
            <Container>
              <div tw="flex flex-wrap py-5 items-end md:gap-5 md:text-lg lg:flex-nowrap">
                <div tw="relative w-full [padding-left: 40px] [padding-bottom: 20px] md:pb-0 lg:w-1/5">
                  <a href="https://www.bundesstiftung-gleichstellung.de/" target="_blank">
                    <span>
                      <Icon
                        svg={LogoBSG}
                        title="Bundesstiftung Gleichstellung"
                        tw="absolute left-0 h-10 w-10 lg:h-10 xl:h-10 float-left mr-5"
                        asImage
                      />
                    </span>
                    <span tw="text-base m-[10px 0 0 10px] block">
                      Bundesstiftung Gleichstellung
                    </span>
                  </a>
                </div>
                <div tw="w-full [padding-bottom: 20px] md:pb-0 lg:w-1/5 h-auto [vertical-align: top]">
                  <span tw="text-base">Karl-Liebknecht-Str. 34, 10178 Berlin</span>
                </div>
                <div tw="w-full [padding-bottom: 20px] md:pb-0 lg:w-1/5 h-auto [vertical-align: top]">
                  <a
                    href="tel:004930994057000"
                    tw="text-base"
                    aria-label="Home"
                  >+49 30 9940570 00</a>
                </div>
                <div tw="w-full pb-5 md:pb-0 lg:w-2/5">
                  <a
                    href="mailto:gleichstellungsbericht@bundesstiftung-gleichstellung.de"
                    tw="text-base"
                    aria-label="Home"
                  >gleichstellungsbericht@bundesstiftung-gleichstellung.de</a>
                </div>
              </div>
            </Container>
          </div>
        </footer>
      </div>
      <script id="dacs" src="https://download.digiaccess.org/digiaccess" defer></script>
    </div>
  )
}

export default Layout
