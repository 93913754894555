import { React } from "react"
import LogoWording from "../../../content/assets/logo-wording.svg"
import LogoNav from "../../../content/assets/gleichstellungsberichte-logo.svg"
import { Link } from "gatsby"
import Icon from "../icon"
import tw from "twin.macro"

const Logo = ({ collapsed }) => {
  const collapsedStylesLogo = tw`h-16 lg:h-11 xl:h-15`
  const normalStylesLogo = tw`h-18 lg:h-24 xl:h-30`
  const collapsedStylesSignet = tw`w-28 lg:w-1/2 pr-2 block lg:transition-all duration-500 ease-in-out mt-0`
  const normalStylesSignet = tw`w-30 lg:w-full pr-2 block lg:transition-all lg:duration-500 ease-in-out mt-2`
  const collapsedStylesWording = tw`opacity-0`
  const normalStylesWording = tw`opacity-100 delay-75`

  return (
    <Link
      to="/"
      tw="flex w-32 flex-wrap items-center duration-300 ease-in-out lg:mb-2 lg:w-40 lg:transition-all xl:mb-0 xl:w-48"
      aria-label="Home"
      css={[collapsed && collapsedStylesLogo, !collapsed && normalStylesLogo]}
    >
      <Icon
        svg={LogoNav}
        title="Bundesstiftung Gleichstellung"
        css={[
          collapsed && collapsedStylesSignet,
          !collapsed && normalStylesSignet,
        ]}
        asImage
      />
    </Link>
  )
}

export default Logo
