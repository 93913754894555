import "twin.macro"
import PropTypes from "prop-types"
import React from "react"
import tw from "twin.macro"

const ContainerPost = ({ children, existCategory }) => {

  const titleStyles = [
    existCategory === null ? tw`bg-[#FFFFFF]` : (existCategory ? tw`bg-[#F4F9F6]` : tw`bg-[#F7F7FB]`),
  ]

  return (
    <div tw="flex w-full justify-center" css={[titleStyles]}>
        <div tw="w-full max-w-screen-2xl px-4 sm:px-10 lg:px-20">{children}</div>
    </div>
  )
}

ContainerPost.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ContainerPost
