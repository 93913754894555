



import { Link } from "gatsby"
import { React, useState, useRef, useEffect } from "react"
import Icon from "../icon"
import Plus from "../../images/svgs/Icon-open.svg"
import Minus from "../../images/svgs/icon-close.svg"
import tw from "twin.macro"
import Drawer from "./drawer"
import uniqueId from "lodash/uniqueId"

const Menu = ({ menuItems, onNavItemClick, collapsed }) => {

  const plusIcon = Plus
  const minusIcon = Minus

  var [openDrawer, setOpenDrawer] = useState("")
  //const toggleNavigationDrawer = id => setOpenDrawer(openDrawer == id ? "" : id)

  const [accordionItems, setAccordionItems] = useState(
    menuItems.map(({ id, uri, label, childItems, }) => ({
      id: id || uniqueId("accordion-"),
      label: label,
      uri: uri,
      childItems: childItems?.nodes || [],
      isOpen: false,
    }))
  )

  const navNode = useRef()

  useEffect(() => {
    {/*openDrawer
      ? disableBodyScroll(navNode, { reserveScrollBarGap: true })
      : enableBodyScroll(navNode)*/}
  }, [openDrawer])

  const handleItemClick = () => {
    onNavItemClick()
  }

  const toggleNavigationDrawer = index => {
    setOpenDrawer(index);
    setAccordionItems(
      accordionItems.map((accordionItem, i) => {
        if (index === i) {
          accordionItem.isOpen = !accordionItem.isOpen
        }
        return accordionItem
      })
    )
  }

  const collapsedStyles = tw`lg:top-32 xl:top-22 lg:h-[calc(100vh - 8rem)] xl:h-auto`
  const normalStyles = tw`lg:top-52 xl:top-48 lg:h-[calc(100vh - 13rem)] xl:h-auto`

  return (
    <nav ref={navNode} tw="mr-0 w-full lg:w-auto ml-auto" role="navigation">
      <ul tw="grid gap-y-2 text-2xl md:text-3xl lg:flex lg:flex-row lg:space-x-5 lg:space-y-0 lg:text-xl xl:space-x-7">
        {accordionItems.map(({ label, id, uri, childItems, isOpen }, index) => (
          <li tw="lg:mb-1" key={`nav-${id}`}>
            {childItems.length > 0 && (
              <div>
                <button
                  id={`${id}-header`}
                  onClick={() => { toggleNavigationDrawer(index) }}
                  tw="flex w-full cursor-pointer items-center justify-between lg:block lg:w-auto"
                  key={`nav-link-${id}`}
                >
                  <span
                    tw="py-3 font-bold! lg:font-regular! navLink-animated underline-anthracite lg:underline-orange"
                    className="global-nav-item"
                    css={[openDrawer === id ? tw`navLink-animated-active` : tw``]}
                  >
                    {label}
                  </span>
                  <Icon
                    svg={isOpen ? Minus : Plus}
                    tw="h-8 w-5 md:h-9 md:w-6 lg:hidden"
                  />
                </button>

                {childItems && childItems.map((item, index) => (
                  <div
                    aria-labelledby={`${id}-header`}
                    hidden={isOpen ? "" : true}
                    tw="text-xl py-1 lg:hidden"
                    key={`panel-${index}`}
                  >
                    <a
                      href={item.uri}
                      onClick={() => {
                        toggleNavigationDrawer("")
                        handleItemClick()
                        setOpenDrawer("")
                      }}
                    >{item.label}</a>
                  </div>
                ), [isOpen])}
              </div>
            )}

            {childItems.length == 0 && (
              <Link
                tw="flex w-full cursor-pointer items-center justify-between lg:block lg:w-auto"
                to={uri}
                key={`nav-link-internal-${index}`}
                onClick={() => { toggleNavigationDrawer(""); handleItemClick(); }}
              >
                <span
                  tw="py-3 navLink-animated font-bold! lg:font-regular! underline-anthracite lg:underline-orange"
                  className="global-nav-item"
                  css={[openDrawer === id ? tw`navLink-animated-active` : tw``]}
                >
                  {label}
                </span>
              </Link>
            )}

            <div
              tw="absolute top-0 z-50 bg-neutral-50 pt-4 md:pt-0"
              css={[
                openDrawer === index ? tw`hidden lg:flex` : tw`hidden`,
                collapsed && collapsedStyles,
                !collapsed && normalStyles,
              ]}
            >
              <Drawer
                id={index}
                label={label}
                items={childItems}
                toggleNavigationDrawer={toggleNavigationDrawer}
                onItemClick={handleItemClick}
              />
            </div>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Menu