import { React, useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { Turn as Hamburger } from "hamburger-react"
import Menu from "./menu"
import Container from "../../components/container"
import Icon from "../../components/icon"
import Digiaccess from "../social/digiaccess"
import LinkedIn from "../social/linkedin"
import LogoBSG from "../../../content/assets/logo.svg"
import Bar from "./bar"
import Logo from "./logo"
import { throttle } from "lodash"

const Header = ({ mainMenuItems, footernMenuItems, metaMenuItems, breadcrumbs }) => {

  const offset = 80
  const [showNavigation, toggleNavigation] = useState(false)

  const handleSelectedNavItem = () => {
    toggleNavigation(false)
  }

  const navNode = useRef()

  useEffect(() => {
    {/*showNavigation
      ? disableBodyScroll(navNode, { reserveScrollBarGap: true })
      : enableBodyScroll(navNode)*/}
  }, [showNavigation])

  const [collapsed, setCollapsed] = useState(false)

  const collapsedStyles = tw`top-18 h-[calc(100vh - 4.5rem)] lg:h-auto`
  const normalStyles = tw`top-28 h-[calc(100vh - 7rem)] lg:h-auto`

  const collapsedMetaStyles = tw`hidden`
  const normalMetaStyles = tw`justify-between  py-5 border-b-[1px] border-b-[#FC8041] pb-0 hidden lg:flex`

  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    const updateScrollState = () => {
      if (isBrowser && window.scrollY > offset && !collapsed) {
        setCollapsed(true)
      }
      if (isBrowser && window.scrollY < offset && collapsed) {
        setCollapsed(false)
      }
    }

    if (isBrowser) {
      window.addEventListener("scroll", throttle(updateScrollState, 60))
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener("scroll", updateScrollState)
      }
    }
  }, [collapsed, isBrowser, offset])

  return (
    <Bar collapsed={collapsed}>
      <div tw="flex w-full justify-center">
        <div tw="w-full max-w-full px-0">
          {/** META MENU */}
          <div
            css={[
              showNavigation ? tw`justify-between flex py-7 border-b-[1px] border-b-[#FC8041] pb-12 pt-12` : tw`justify-between flex py-7 border-b-[1px] border-b-[#FC8041] pb-20 pt-12`,
              collapsed && collapsedMetaStyles,
              !collapsed && normalMetaStyles,
            ]}
          >
            <div tw="w-auto">
              <div tw="flex flex-wrap items-center">
                <div tw="w-auto mr-14"><a href="#"></a></div>
              </div>
            </div>
            <div tw="w-auto">
              <div tw="flex flex-wrap items-center">
                <div tw="w-auto hidden lg:block">
                  <ul tw="flex items-center ml-1 md:text-3xl lg:text-xl text-orange-100 [font-family: Gabarito-Regular] [font-style: normal] [font-weight: 400] [line-height: 120%] [letter-spacing: -0.08px] px-4 sm:px-10 lg:px-20 2xl:[padding-right: calc(50vw - 688px)] md:pb-3.5">
                    {metaMenuItems.map(({ label, url, id }) => (
                      <li tw="ml-9 hover:text-gray-700 [font-size: 16px]" key={`nav-${id}`}>
                        <Link to={url || `/`} tw="inline-block py-2">
                          {label}
                        </Link>
                      </li>
                    ))}
                    <li>
                      <div tw="items-center gap-3 hidden lg:flex pl-10">
                        <Digiaccess />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/** META MENU */}

          <div tw="flex items-center justify-between sm:flex-row lg:flex-row xl:flex-row p-0 bg-white px-4 sm:px-10 lg:px-20 2xl:[padding: 0 calc(50% - 688px)]">
            <Logo collapsed={collapsed} />
            <div
              ref={navNode}
              tw="border-t-2 border-t-gray-200 lg:border-t-0 absolute left-0 z-20 h-screen w-full flex-col items-start bg-white px-4 pb-2 pt-4 sm:px-10 md:py-5 lg:static lg:top-auto lg:-mb-2.5 lg:flex-row lg:items-center lg:justify-between lg:bg-transparent lg:px-0 lg:py-0 lg:pb-0 xl:w-auto xl:justify-evenly"
              css={[
                showNavigation ? tw`flex` : tw`hidden lg:flex`,
                collapsed && collapsedStyles,
                !collapsed && normalStyles,
              ]}
            >
              <Menu
                menuItems={mainMenuItems}
                onNavItemClick={handleSelectedNavItem}
                collapsed={collapsed}
              />
              <div tw="mt-6 flex-grow flex-col items-end justify-end text-sm flex lg:hidden">
                <ul tw="font-bold text-xl md:text-2xl text-lilac-500">
                  {metaMenuItems.map(({ label, url, id }) => (
                    <li tw="" key={`nav-${id}`}>
                      <Link
                        to={url || `/`}
                        tw="inline-block py-2"
                        onClick={() => { handleSelectedNavItem(); }}
                      >
                        {label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div tw="-mr-2 flex items-center gap-3 lg:hidden">
              <Digiaccess />
              <div tw="z-40">
                <Hamburger
                  toggled={showNavigation}
                  label="Navigation anzeigen"
                  toggle={state => { toggleNavigation(state) }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Bar>
  )
}

export default Header
