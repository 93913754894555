import "twin.macro"
import { Link, graphql } from "gatsby"
import ArrowRight from "../images/svgs/arrow_link.svg"
import ArrowRightBlue from "../images/svgs/arrow_link_blue.svg"
import ArrowRightGreen from "../images/svgs/arrow_link_green.svg"
import Icon from "../components/icon"
import PropTypes from "prop-types"
import React from "react"
import tw from "twin.macro"

const Breadcrumbs = (data) => {
  // Add check for aktuelles and modify items array
  const modifiedItems = [...data.items];

  if (modifiedItems.length > 0 && modifiedItems[1]?.url?.startsWith("/aktuelles/")) {
    modifiedItems.splice(1, 0, {
      text: "Aktuelles",
      url: "/aktuelles"
    });
  }

  if (modifiedItems.length > 0 && modifiedItems[1]?.url?.startsWith("/blog/")) {
    modifiedItems.splice(1, 0, {
      text: "Blog",
      url: "/blog"
    });
  }

  const titleStyles = [data.existCategory === null ? tw`bg-[#FFF] text-[#403882]` : (data.existCategory ? tw`bg-[#F4F9F6] text-[#316B64]` : tw`bg-[#F7F7FB] text-[#403882]`),]
  const linkStyles = [data.existCategory === null ? tw`border-b-2 border-b-[#403882]` : (data.existCategory ? tw`border-b-2 border-b-[#316B64]` : tw`border-b-2 border-b-[#403882]`),]
  const arrow = data.existCategory === null ? ArrowRightBlue : (data.existCategory ? ArrowRightGreen : ArrowRightBlue);

  return (
    <nav
      aria-label="Breadcrumb"
      tw="flex text-sm col-span-9 col-start-1 mb-0 lg:mb-[60px] mt-[30px] lg:col-span-9 lg:col-start-4 xl:col-start-3"
      css={[titleStyles]}
    >
      <ol tw="inline-flex flex-wrap items-start [font-family: Gabarito-Regular] [font-size: 0.9375rem] font-normal [line-height: 160%] [letter-spacing: 0.24px]">
        {modifiedItems.map((item, index) => (
          <li key={`breadcrumb-${index}`}>
            {item?.url && item.url !== "" && !item.url.includes("page_id") && (index+1 < modifiedItems.length) ? (
              <Link
                to={item.url}
                css={[linkStyles]}
              >{item.text}</Link>
            ) : (
              <span>{item.text}</span>
            )}
            {index < modifiedItems.length - 1 && <span tw="mx-1"><Icon svg={arrow} tw="inline ml-2 mr-2 mb-1 h-[16px] w-[21px]" /></span>}
          </li>
        ))}
      </ol>
    </nav>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default Breadcrumbs