import { React } from "react"
import { Link } from "gatsby"
import "twin.macro"
import Icon from "../icon"
import Arrow from "../../images/svgs/arrow.svg"
import "../../css/navigation.css"

const Drawer = ({ items, toggleNavigationDrawer, label, onItemClick }) => {
  const handleClick = () => {
    onItemClick()
  }
  return (
    <ul tw="z-40 grid lg:block lg:h-full lg:columns-1 lg:column-fill-auto bg-white">
      {items.map(({ label, uri, id }) => (
        <li tw="p-[14px 20px] hover:bg-[rgba(230, 228, 243, 0.30)]" key={`drawer-${id}`}>
          <Link
            to={uri}
            partiallyActive={true}
            tw="[font-size: 20px] [font-style: normal] [font-weight: 400] [line-height: 150%] [letter-spacing: 0.2px] text-[#000000]"
            activeClassName="active"
            onClick={() => {
              toggleNavigationDrawer("")
              handleClick()
            }}
          >
            {label}
          </Link>
        </li>
      ))}
    </ul>
  )
}

Drawer.propTypes = {}

export default Drawer
