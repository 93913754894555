import React from "react"
// import PropTypes from 'prop-types'
import digiaccess from "../../images/svgs/digiaccess.svg"
import "twin.macro"
import Icon from "../icon"

/*const open = document.getElementById("opener");

open.addEventListener("click", (e) => {
  e.preventDefault();
  const digiaccess = window.Digiaccess;
  digiaccess.toggle();
});

const style = document.createElement('style');
style.textContent =
  #dAopener {
   display: none !important;
  }
  ;

document.head.appendChild(style);
*/

const Digiaccess = props => {

  const handleClick = (e) => {
    e.preventDefault();
    window.Digiaccess.toggle();
  };

  React.useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      #dAopener {
        display: none !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <a
      href="#"
      target="_blank"
      rel="noreferrer noopener"
      tw="flex h-11 w-11 items-center rounded-full p-2 text-anthracite-500 hover:text-lilac-500 focus:text-lilac-500 motion-safe:transition-colors motion-safe:duration-300 [border: 2px solid #FC8041]"
      onClick={handleClick}
      aria-label="digiaccess"
      className="da-tool"
    >
      <Icon svg={digiaccess} tw="h-7 w-7 fill-current" />
    </a>
  )
}

Digiaccess.propTypes = {}

export default Digiaccess
