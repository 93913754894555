import React from "react"
import tw from "twin.macro"
// import { debounce } from '../../utilities/helpers';

const Bar = ({ children, collapsed }) => {
  const collapsedStyles = tw`h-18 lg:h-auto xl:h-auto shadow-[0 1px 0px 0px rgb(252, 128, 65, 1)]`
  const normalStyles = tw`h-28 lg:h-52 xl:h-48`

  return (
    <header
      className="global-header"
      role="banner"
      tw="fixed z-30 flex w-full sm:w-full flex-shrink-0 items-center bg-white transition-[height] duration-500 ease-in-out"
      css={[collapsed && collapsedStyles, !collapsed && normalStyles]}
    >
      {children}
    </header>
  )
}

export default Bar
